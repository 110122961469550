import { PageQoModel } from '@/models/common/CommonModel'
export interface LogModelBase {
  id: string;
  applicationName?: string;
  contextId?: string;
  level: string;
  loggerName: string;
  message: string;
}

export interface LogModelQo extends LogModelBase {
  timeMillisGt?: number;
  timeMillisLt?: number;
  thrown?: string;
  page?: PageQoModel;
}

export const LogQoData = (): LogModelQo => {
  return {
    id: "",
    level: "",
    loggerName: "",
    message: "",
  }
}

export interface LogModel extends LogModelBase {
  timeMillis: number;
  thrown?: ThrownModel;
}

export interface ThrownModel {
  name: string;
  message: string;
  stackTraces?: StackTraceModel[];
}

export interface StackTraceModel {
  className: string;
  exact?: boolean;
  file?: string;
  line: number;
  location?: string;
  method: string;
  version: string;
}

export const LogData: LogModel = {
  id: "",
  level: "",
  loggerName: "",
  message: "",
  timeMillis: 0
}


