











































































import DateUtil from 'global-ui/packages/utils/DateUtil';
import { LogModel, LogModelQo, LogQoData } from '@/models/logs/LogModel';
import { LogPage } from '@/api/logs/LogApi';
import page from '@/mixins/page';
import Component, { mixins } from 'vue-class-component';
import { JSONView } from 'vue-json-component';

@Component({
  name: 'BackEndLog',
  components: { 'json-view': JSONView }
})
export default class BackEndLog extends mixins(page) {
  DateUtil: any = DateUtil;
  loadingOuter: boolean = false;
  logSearchModel = LogQoData();
  logListData: LogModel[] = [];
  levels: any[] = [
    { key: 'debug', label: '调试' },
    { key: 'info', label: '信息' },
    { key: 'error', label: '错误' }
  ];
  // 异常详情显示标签
  dialogVisible: boolean = false;
  // 当前选择的requestBody
  thrown: any = '';

  created() {
    this.logSearchModel.contextId = this.$route.query.contextId as string;
    this.getLogPage();
  }

  handleSearch(): void {
    this.pageIndex = 1;
    this.getLogPage();
  }

  // 获取表单列表
  getLogPage(): void {
    let params: LogModelQo = {
      ...this.logSearchModel,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.loadingOuter = true;
    LogPage(params).then((res: Record<string, any>) => {
      if (res.code == '1' && res.data) {
        this.loadingOuter = false;
        this.logListData = res.data.list;
        this.total = res.data.total;
      }
    });
  }

  showThrownDialog(thrown: any) {
    this.dialogVisible = true;
    this.thrown = thrown;
  }

  /**
   * 跳转至某页
   */
  changePage(page) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getLogPage();
  }
  /**
   * 跳转至某页
   */
  changePageSize(pageSize) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getLogPage();
  }
}
